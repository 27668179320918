import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';

export const UserDetails: React.FC = () => {
  const nav = useNavigate();
  const onBack = () => {
    nav(-1);
  };
  return (
    <Box>
      <Typography fontWeight="bold" variant="h5">
        User Details
      </Typography>
      <Button onClick={onBack} startIcon={<ChevronLeft />}>
        Back
      </Button>
      <Box>
        <Typography>Role</Typography>
        <Select value={0}>
          <MenuItem value={0}>user</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};
