export const mockAssets = [
  {
    symbol: 'USYC',
    amount: 190,
    name: 'Hashnote US Yield Coin',
  },
  {
    symbol: 'CC',
    amount: 1090,
    name: 'Canton Coin',
  },
  {
    symbol: 'CCWETH',
    amount: 190,
    name: 'Canton Wrapped Ethereum',
  },
];
