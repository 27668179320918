import React from 'react';
import { useNavigate } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';

const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const top = [
  { label: 'Dashboard', to: '/', icon: <DashboardOutlinedIcon /> },
  {
    label: 'notifications',
    to: '/notifications',
    icon: <NotificationsNoneOutlinedIcon />,
  },
  { label: 'users', to: '/users', icon: <GroupOutlinedIcon /> },
];
const bottom = [
  { label: 'Account', to: '/account', icon: <AccountCircleOutlinedIcon /> },
  { label: 'Log out', to: '/logout', icon: <LoginOutlinedIcon /> },
];
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}));
interface ISidebarItem {
  label: string;
  to: string;
  icon: React.ReactNode;
}
const SidebarItem: React.FC<ISidebarItem & { open: boolean }> = ({ label, to, icon, open }) => {
  const nav = useNavigate();

  return (
    <ListItem onClick={() => nav(to)} disablePadding key={label}>
      <ListItemButton
        sx={[
          {
            minHeight: 48,
            px: 2.5,
          },
          open
            ? {
                justifyContent: 'initial',
              }
            : {
                justifyContent: 'center',
              },
        ]}
      >
        <ListItemIcon
          sx={[
            {
              minWidth: 0,
              justifyContent: 'center',
            },
            open
              ? {
                  mr: 3,
                }
              : {
                  mr: 'auto',
                },
          ]}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          sx={[
            open
              ? {
                  opacity: 1,
                }
              : {
                  opacity: 0,
                },
            { textTransform: 'capitalize' },
          ]}
          primary={label}
        />
      </ListItemButton>
    </ListItem>
  );
};
export const SidebarExpandable: React.FC = () => {
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const topMenuItems = top.map((i) => {
    return <SidebarItem key={i.label} label={i.label} to={i.to} icon={i.icon} open={open} />;
  });
  const bottomMenuItems = bottom.map((i) => {
    return <SidebarItem key={i.label} label={i.label} to={i.to} icon={i.icon} open={open} />;
  });
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
        {open && <Typography fontWeight="bold">Neuchatel</Typography>}
        <IconButton
          onClick={open ? handleDrawerClose : handleDrawerOpen}
          sx={{ ml: open ? 'auto' : 0 }}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
      {topMenuItems}
      <Divider />
      <Box sx={{ mt: 'auto' }}>{bottomMenuItems}</Box>
    </Drawer>
  );
};
