import React from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GridValidRowModel,
} from '@mui/x-data-grid';
import ScrollableCell from './ScrollableCell';

// Note: The @mui/x-data-grid-pro has a built-in scrollable/resizable cell feature, but the
// corresponding open source version @mui/x-data-grid lacks these features. The following is a
// utility function for wrapping all cells into custom ScrollableCell components.
export const withScrollableCell = <R extends GridValidRowModel>(
  columns: GridColDef<R>[],
): GridColDef<R>[] => {
  return columns.map((column) => {
    const originalRenderCell = column.renderCell;
    const originalValueGetter = column.valueGetter;
    return {
      ...column,
      renderCell: (params: GridRenderCellParams<R>) => {
        let content: React.ReactNode | null = null;
        if (originalRenderCell) {
          content = originalRenderCell(params) as React.ReactNode;
        } else if (originalValueGetter) {
          const valueGetterParams = params as GridValueGetterParams<R>;
          content = originalValueGetter(valueGetterParams) as React.ReactNode;
        } else {
          content = params.value as React.ReactNode;
        }
        return <ScrollableCell content={content} />;
      },
    };
  });
};
