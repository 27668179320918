export interface IUserRow {
  name: string;
  email: string;
  role: string;
  wallets: string[];
}
export const mockUserRows: IUserRow[] = [
  {
    name: 'Jodi',
    email: 'jodi@gmail.com',
    role: 'User',
    wallets: ['1', '2'],
  },
  {
    name: 'Taylor K',
    email: 'jodi@gmail.com',
    role: 'User',
    wallets: ['1', '2'],
  },
  {
    name: 'Jodi',
    email: 'jodi@gmail.com',
    role: 'User',
    wallets: ['1', '2'],
  },
  {
    name: 'Taylor K',
    email: 'jodi@gmail.com',
    role: 'User',
    wallets: ['1', '2'],
  },
  {
    name: 'Jodi',
    email: 'jodi@gmail.com',
    role: 'User',
    wallets: ['1', '2'],
  },
  {
    name: 'Taylor K',
    email: 'jodi@gmail.com',
    role: 'User',
    wallets: ['1', '2'],
  },
  {
    name: 'Jodi',
    email: 'jodi@gmail.com',
    role: 'User',
    wallets: ['1', '2'],
  },
  {
    name: 'Taylor K',
    email: 'jodi@gmail.com',
    role: 'User',
    wallets: ['1', '2'],
  },
  {
    name: 'Taylor K',
    email: 'jodi@gmail.com',
    role: 'User',
    wallets: ['1', '2'],
  },
  {
    name: 'Taylor K',
    email: 'jodi@gmail.com',
    role: 'User',
    wallets: ['1', '2'],
  },
];
