import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { UsersTable } from '../../components/UsersTable';
import { mockUserRows } from '../../tests/mocks/mockUserRows';
import { UserDetails } from './UserDetailsPage';

export const UsersPage: React.FC = () => {
  const main = (
    <>
      <Typography variant="h5" fontWeight="bold">
        Users
      </Typography>
      <UsersTable rows={mockUserRows} />
    </>
  );
  return (
    <Box sx={{ p: 2 }}>
      <Routes>
        <Route path="/" element={main} />
        <Route path="/details/:username" element={<UserDetails />} />
      </Routes>
    </Box>
  );
};
