import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { SidebarExpandable } from '../components/SidebarExpandable';

export const Layout: React.FC = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <SidebarExpandable />

      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
};
