import React from 'react';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { PrepareTransfer } from '../../components/PrepareTransfer';
import { WalletPreview } from '../../components/WalletPreview';
import { mockAssets } from '../../tests/mocks/mockAssets';

export const DashboardPage: React.FC = () => {
  const [openTransferForm, setOpenTransferForm] = React.useState(false);
  const onOpen = () => {
    setOpenTransferForm(true);
  };
  const onClose = () => {
    setOpenTransferForm(false);
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">Hello, Katy</Typography>
          <Typography variant="caption">Aug 27 2024</Typography>
        </Box>
        <Box sx={{ ml: 'auto' }}>
          <Button
            variant="contained"
            onClick={onOpen}
            sx={{
              mr: 1,
              textTransform: 'capitalize',
            }}
            startIcon={<AccountBalanceWalletOutlinedIcon />}
          >
            Connect a wallet
          </Button>
          <Button
            variant="contained"
            onClick={onOpen}
            sx={{
              textTransform: 'capitalize',
            }}
          >
            Make a transfer
          </Button>
        </Box>
      </Box>

      <WalletPreview assets={mockAssets} />

      <Dialog open={openTransferForm} onClose={onClose}>
        <PrepareTransfer onClose={onClose} />
      </Dialog>
    </Box>
  );
};
