import React from 'react';
import {
  Box,
  Button,
  DialogContent,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

interface PrepareTransferProps {
  onClose: () => void;
}
export const PrepareTransfer: React.FC<PrepareTransferProps> = ({ onClose }) => {
  const [asset, setAsset] = React.useState('');
  const handleChange = (event: SelectChangeEvent) => {
    setAsset(event.target.value);
  };
  return (
    <DialogContent>
      <Typography fontWeight="bold">Make a transfer</Typography>
      <Typography variant="caption">
        Once the transfer request is ready to be sent, you will be prompted to sign the request for
        confirmation.
      </Typography>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography>Wallet</Typography>
        <OutlinedInput placeholder="Paste a new recipient ID" fullWidth />
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography>Recipient</Typography>
        <OutlinedInput placeholder="Recipeint ID" fullWidth />
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography>Transfer amount</Typography>
        <Box sx={{ display: 'flex' }}>
          <Select
            onChange={handleChange}
            displayEmpty
            renderValue={(value: string) => {
              if (!value) {
                return <Typography color="gray">Select asset</Typography>;
              }
              return value;
            }}
            sx={{ mr: 2 }}
            fullWidth
            value={asset}
          >
            <MenuItem value="USDC">USDC</MenuItem>
          </Select>
          <OutlinedInput fullWidth />
        </Box>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography>Description (optional)</Typography>
          <OutlinedInput fullWidth placeholder="Notes..." multiline minRows={4} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button>Send Transfer</Button>
      </Box>
    </DialogContent>
  );
};
